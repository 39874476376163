const state = {
  isVisible: false,
  type: "success",
  text: "Hello World!",
};

const getters = {
  isVisible: (state) => state.isVisible,
  type: (state) => state.type,
  text: (state) => state.text,
};

const mutations = {
  commitIsVisible: (state, isVisible) => {
    state.isVisible = isVisible;
  },
  commitType: (state, type) => (state.type = type),
  commitText: (state, text) => (state.text = text),
};

const actions = {
  setIsVisible: (context, isVisible) =>
    context.commit("commitIsVisible", isVisible),
  setType: (context, type) => context.commit("commitType", type),
  setText: (context, text) => context.commit("commitText", text),
  setSuccess: (context, text) => {
    context.commit("commitType", "success");
    context.commit("commitIsVisible", true);
    context.commit("commitText", text);
  },
  setWarning: (context, text) => {
    context.commit("commitType", "warning");
    context.commit("commitIsVisible", true);
    context.commit("commitText", text);
  },
  setError: (context, text) => {
    if (text.response?.data)
      context.commit("commitText", text.response.data.errors[0].details.detail);
    else context.commit("commitText", text);
    context.commit("commitType", "error");
    context.commit("commitIsVisible", true);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
