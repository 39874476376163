// import dependencies
import axios from "axios";
import jwt from "jsonwebtoken";

const state = {
  user: null,
  scopes: [],
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  user: (state) => state.user,
  hasScope: (state) => (scope) => {
    if (state.scopes.length === 0) return false;
    else
      return state.scopes
        .map((scope) => `${scope.resource}:${scope.scope}:${scope.action}`)
        .includes(scope);
  },
};

const mutations = {
  commitUser: (state, user) => (state.user = user),
  commitScopes: (state, scopes) => (state.scopes = scopes),
};

const actions = {
  login: async (context, token) => {
    return new Promise((resolve, reject) => {
      const parsedToken = jwt.decode(token);
      if (Date.UTC() >= parsedToken.exp * 1000) {
        reject();
      } else {
        return Promise.all([
          context.dispatch("loadUser", parsedToken.sub),
          context.dispatch("loadScopes", parsedToken.sub),
        ])
          .then(() => {
            localStorage.setItem("access_token", token);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
  logout: (context) => {
    localStorage.removeItem("access_token");
    context.commit("commitUser", null);
  },
  loadUser: async (context, uuid) => {
    return axios
      .get(`/users/${uuid}`, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        context.commit("commitUser", {
          id: response.data.data.id,
          ...response.data.data.attributes,
        });
      })
      .catch((error) => {
        throw error;
      });
  },
  loadScopes: async (context, uuid) => {
    return axios
      .get(`/users/${uuid}/scopes`, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        context.commit(
          "commitScopes",
          response.data.data.map((scope) => ({
            id: scope.id,
            resource: scope.attributes.resource,
            scope: scope.attributes.scope,
            action: scope.attributes.action,
          })),
        );
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
