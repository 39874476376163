<template>
  <v-navigation-drawer
    v-model="isDrawerVisible"
    color="primary"
    app
    temporary
    dark
  >
    <v-list subheader>
      <v-subheader>Dashboard</v-subheader>
      <v-list-item :to="{ name: 'Dashboard' }" link>
        <v-list-item-icon>
          <v-icon> mdi-view-dashboard </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-subheader>Workspace</v-subheader>
    <v-divider></v-divider>
    <v-list subheader>
      <v-list-item
        v-if="hasScope(`users:${scopeAll}:read`)"
        :to="{ name: 'ListUsers' }"
        link
      >
        <v-list-item-icon>
          <v-icon> mdi-account-badge-horizontal-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>User Management</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-subheader>Codebooks</v-subheader>
      <v-list-item :to="{ name: 'ListCodebooksView' }" link>
        <v-list-item-icon>
          <v-icon> mdi-label-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Codebooks</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-subheader>Projects</v-subheader>
      <v-list-item :to="{ name: 'ListProjectsView' }" link>
        <v-list-item-icon>
          <v-icon> mdi-shape-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Projects</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-subheader>Settings</v-subheader>
      <v-list-item
        v-if="user"
        :to="{ name: 'EditUsers', params: { uuid: user.id } }"
        link
      >
        <v-list-item-icon>
          <v-icon> mdi-account </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Account</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-spacer></v-spacer>

    <template v-slot:append>
      <v-list>
        <v-list-item
          v-if="user"
          dense
          href="https://wzb.eu/de/impressum"
          target="_blank"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Impressum</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="user"
          dense
          href="https://wzb.eu/de/datenschutz"
          target="_blank"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Data Privacy Statement</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="caption"
              >Letztes Update: 03.03.2022</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="pa-2">
        <v-btn color="secondary" @click="logout()" block>Logout</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
// import dependencies
import { mapGetters } from "vuex";

export default {
  name: "Drawer",
  data: () => ({
    scopeAll: process.env.VUE_APP_SCOPE_ALL,
  }),
  computed: {
    ...mapGetters("authentication", ["isAuthenticated", "hasScope", "user"]),
    isDrawerVisible: {
      get() {
        return this.$store.getters["navigation/isVisible"];
      },
      set(value) {
        this.$store.dispatch("navigation/setIsVisible", value);
      },
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("authentication/logout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
