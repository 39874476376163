<template>
  <v-app>
    <app-drawer></app-drawer>
    <app-navigation></app-navigation>
    <v-main>
      <app-notification></app-notification>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
// import dependencies
import { mapGetters } from "vuex";

import AppDrawer from "@/components/application/Drawer.vue";
// import components
import AppNavigation from "@/components/application/Navigation.vue";
import AppNotification from "@/components/application/Notification.vue";

export default {
  name: "App",
  components: {
    AppNavigation,
    AppDrawer,
    AppNotification,
  },
  computed: {
    ...mapGetters("navigation", ["isLoading"]),
  },
  mounted() {
    if (localStorage.getItem("dark-mode")) {
      this.$vuetify.theme.dark = true;
    }
  },
};
</script>
