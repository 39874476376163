<template>
  <v-app-bar color="primary" app dark>
    <v-app-bar-nav-icon
      v-if="isAuthenticated"
      @click="isDrawerVisible = !isDrawerVisible"
    ></v-app-bar-nav-icon>
    <v-toolbar-title>
      <span class="font-weight-black"> PolDem Coding Tool</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <span v-if="isAuthenticated">
      <v-btn text @click="logout">Logout</v-btn>
    </span>
    <span v-else>
      <v-btn :to="{ name: 'Login' }" class="mr-2" text>Login</v-btn>
      <v-btn :to="{ name: 'SignUp' }" text>Sign up</v-btn>
    </span>
  </v-app-bar>
</template>

<script>
// import dependencies
import { mapGetters } from "vuex";

export default {
  name: "Navigation",
  computed: {
    ...mapGetters("authentication", ["isAuthenticated"]),
    isDrawerVisible: {
      get() {
        return this.$store.getters["navigation/isVisible"];
      },
      set(value) {
        this.$store.dispatch("navigation/setIsVisible", value);
      },
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("authentication/logout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
