<template>
  <v-alert v-model="isVisible" :type="type" dismissible tile>{{
    text
  }}</v-alert>
</template>

<script>
// import dependenices
import { mapGetters } from "vuex";

export default {
  name: "Notification",
  computed: {
    ...mapGetters("notification", ["type", "text"]),
    isVisible: {
      get() {
        return this.$store.getters["notification/isVisible"];
      },
      set(value) {
        this.$store.dispatch("notification/setIsVisible", value);
      },
    },
  },
};
</script>
