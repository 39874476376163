import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "typeface-lobster/index.css";
import "@mdi/font/css/materialdesignicons.css";
import "echarts";

import axios from "axios";
import moment from "moment";
import Vue from "vue";
import VChart from "vue-echarts";

import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

// register moment globally
Vue.prototype.moment = moment;

// register ECharts globally
Vue.component("v-chart", VChart);

// configure axios
// axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.baseURL = "https://poldem-coding.wzb.eu/api";
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      store.dispatch("authentication/logout").then(() => {
        store.dispatch(
          "notification/setWarning",
          "Your session expired. Please login in again.",
        );
        router.replace({ name: "Login" });
      });
    } else {
      return Promise.reject(error);
    }
  },
);
Vue.prototype.$axios = axios.create({
  timeout: 20000,
});
Vue.prototype.$axios.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("access_token")) {
      config.headers["Authorization"] =
        "bearer " + localStorage.getItem("access_token");
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
