// import dependencies
import Vue from "vue";
import Vuex from "vuex";

import annotation from "./modules/annotation";
import authentication from "./modules/authentication";
// import components
import navigation from "./modules/navigation";
import notification from "./modules/notification";

// register vuex store
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    navigation,
    notification,
    authentication,
    annotation,
  },
});
