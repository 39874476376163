const state = {
  isVisible: false,
  isLoading: false,
};

const getters = {
  isVisible: (state) => state.isVisible,
  isLoading: (state) => state.isLoading,
};

const mutations = {
  commitIsVisible: (state, isVisible) => (state.isVisible = isVisible),
  commitIsLoading: (state, isLoading) => (state.isLoading = isLoading),
};

const actions = {
  setIsVisible: (context, isVisible) =>
    context.commit("commitIsVisible", isVisible),
  setIsLoading: (context, isLoading) =>
    context.commit("commitIsLoading", isLoading),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
