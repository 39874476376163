const state = {
  isDescriptionVisible: true,
  selectedSentences: [],
  selectionActive: false,
  loadedSentences: [],
  observationOpen: null,
};

const getters = {
  isDescriptionVisible: (state) => state.isDescriptionVisible,
  selectedSentences: (state) => state.selectedSentences,
  selectionActive: (state) => state.selectionActive,
  loadedSentences: (state) => state.loadedSentences,
  observationOpen: (state) => state.observationOpen,
};

const mutations = {
  commitIsDescriptionVisible: (state, isDescriptionVisible) => {
    state.isDescriptionVisible = isDescriptionVisible;
  },
  commitSelectedSentences: (state, selectedSentences) => {
    state.selectedSentences = selectedSentences;
  },
  commitSelectionActive: (state, selectionActive) => {
    state.selectionActive = selectionActive;
  },
  commitLoadedSentences: (state, loadedSentences) => {
    state.loadedSentences = loadedSentences;
  },
  commitObservationOpen: (state, observationOpen) => {
    state.observationOpen = observationOpen;
  },
};

const actions = {
  setIsDescriptionVisible: (context, isDescriptionVisible) => {
    context.commit("commitIsDescriptionVisible", isDescriptionVisible);
  },
  setSelectedSentences: (context, selectedSentences) => {
    context.commit("commitSelectedSentences", selectedSentences);
  },
  setSelectionActive: (context, selectionActive) => {
    context.commit("commitSelectionActive", selectionActive);
  },
  setLoadedSentences: (context, loadedSentences) => {
    context.commit("commitLoadedSentences", loadedSentences);
  },
  setObservationOpen: (context, observationOpen) => {
    context.commit("commitObservationOpen", observationOpen);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
