import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Dashboard" },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "pre-login" */ "@/views/Login.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: () =>
      import(/* webpackChunkName: "pre-login" */ "@/views/PasswordReset.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/password-reset/:token",
    name: "PasswordResetToken",
    component: () =>
      import(/* webpackChunkName: "sign-up" */ "@/views/PasswordReset.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: () =>
      import(/* webpackChunkName: "pre-login" */ "@/views/SignUp.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
      scopes: [],
    },
  },
  {
    path: "/users",
    component: () =>
      import(/* webpackChunkName: "users" */ "@/views/users/Index.vue"),
    meta: {
      requiresAuth: true,
      scopes: [],
    },
    children: [
      {
        path: "list",
        name: "ListUsers",
        component: () =>
          import(/* webpackChunkName: "users" */ "@/views/users/ListUsers.vue"),
        meta: {
          requiresAuth: true,
          scopes: [`users:${process.env.VUE_APP_SCOPE_ALL}:read`],
        },
      },
      {
        path: ":uuid",
        name: "EditUsers",
        component: () =>
          import(/* webpackChunkName: "users" */ "@/views/users/EditUsers.vue"),
        meta: {
          requiresAuth: true,
          scopes: [
            `users:uuid:read`,
            `users:${process.env.VUE_APP_SCOPE_ALL}:read`,
          ],
        },
      },
    ],
  },
  {
    path: "/users",
  },
  {
    path: "/codebooks",
    component: () =>
      import(/* webpackChunkName: "codebooks" */ "@/views/codebooks/Index.vue"),
    meta: {
      requiresAuth: true,
      scopes: [],
    },
    children: [
      {
        path: "list",
        name: "ListCodebooksView",
        component: () =>
          import(
            /* webpackChunkName: "codebooks" */ "@/views/codebooks/ListCodebooksView.vue"
          ),
        meta: {
          requiresAuth: true,
          scopes: [],
        },
      },
      {
        path: "create",
        name: "CreateCodebooksView",
        component: () =>
          import(
            /* webpackChunkName: "codebooks" */ "@/views/codebooks/CreateCodebooksView.vue"
          ),
        meta: {
          requiresAuth: true,
          scopes: [`codebooks:${process.env.VUE_APP_SCOPE_ALL}:create`],
        },
      },
      {
        path: ":uuid/update",
        name: "SettingsCodebooksView",
        component: () =>
          import(
            /* webpackChunkName: "codebooks" */ "@/views/codebooks/SettingsCodebooksView.vue"
          ),
        meta: {
          requiresAuth: true,
          scopes: [],
        },
      },
      {
        path: ":uuid",
        name: "EditCodebooksView",
        component: () =>
          import(
            /* webpackChunkName: "codebooks" */ "@/views/codebooks/EditCodebooksView.vue"
          ),
        meta: {
          requiresAuth: true,
          scopes: [],
        },
      },
    ],
  },
  {
    path: "/codebooks",
  },
  {
    path: "/projects",
    component: () =>
      import(/* webpackChunkName: "projects" */ "@/views/projects/Index.vue"),
    meta: {
      requiresAuth: true,
      scopes: [],
    },
    children: [
      {
        path: "list",
        name: "ListProjectsView",
        component: () =>
          import(
            /* webpackChunkName: "projects" */ "@/views/projects/ListProjectsView.vue"
          ),
        meta: {
          requiresAuth: true,
          scopes: [],
        },
      },
      {
        path: "create",
        name: "CreateProjectsView",
        component: () =>
          import(
            /* webpackChunkName: "projects" */ "@/views/projects/CreateProjectsView.vue"
          ),
        meta: {
          requiresAuth: true,
          scopes: [`projects:${process.env.VUE_APP_SCOPE_ALL}:create`],
        },
      },
      {
        path: ":uuid/statistics",
        name: "StatisticsProjectsView",
        component: () =>
          import(
            /* webpackChunkName: "projects" */ "@/views/projects/StatisticsProjectsView.vue"
          ),
        meta: {
          requiresAuth: true,
          scopes: [],
        },
      },
      {
        path: ":uuid/update",
        name: "SettingsProjectsView",
        component: () =>
          import(
            /* webpackChunkName: "projects" */ "@/views/projects/SettingsProjectsView.vue"
          ),
        meta: {
          requiresAuth: true,
          scopes: [],
        },
      },
      {
        path: ":uuid",
        name: "EditProjectsView",
        component: () =>
          import(
            /* webpackChunkName: "projects" */ "@/views/projects/EditProjectsView.vue"
          ),
        meta: {
          requiresAuth: true,
          scopes: [],
        },
      },
      {
        path: ":uuid/jobs/:job_uuid/annotate",
        name: "EditAnnotationView",
        component: () =>
          import(
            /* webpackChunkName: "projects" */ "@/views/annotations/EditAnnotationView.vue"
          ),
        meta: {
          requiresAuth: true,
          scopes: [
            `projects:uuid:update`,
            `projects:${process.env.VUE_APP_SCOPE_ALL}:update`,
          ],
        },
      },
      {
        path: ":uuid/jobs/:job_uuid/preview",
        name: "EditSpreadsheetView",
        component: () =>
          import(
            /* webpackChunkName: "projects" */ "@/views/annotations/EditSpreadsheetView.vue"
          ),
        meta: {
          requiresAuth: true,
          scopes: [
            `projects:uuid:update`,
            `projects:${process.env.VUE_APP_SCOPE_ALL}:update`,
          ],
        },
      },
    ],
  },
  {
    path: "/projects",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("notification/setIsVisible", false);
  if (localStorage.getItem("access_token")) {
    store
      .dispatch("authentication/login", localStorage.getItem("access_token"))
      .then(() => {
        if (to.meta?.requiresAuth) {
          if (
            to.meta.scopes.length === 0 ||
            to.meta.scopes.some((scope) =>
              store.getters["authentication/hasScope"](
                scope.replace("uuid", to.params.uuid),
              ),
            )
          )
            next();
          else next({ name: "Dashboard" });
        } else next({ name: "Dashboard" });
      })
      .catch(() => {
        if (to.meta) {
          if (to.meta.requiresAuth) next({ name: "Login" });
          else next();
        } else {
          next();
        }
      });
  } else {
    if (to.meta?.requiresAuth) next({ name: "Login" });
    else next();
  }
});

export default router;
