import "vuetify/dist/vuetify.min.css";
import "vuetify/src/styles/main.sass";

import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#465362",
        secondary: "#0E7C7B",
        accent: "#F88677",
        error: "#ED254E",
        success: "#4C1E4F",
        warning: "#F9DC5C",
      },
      dark: {
        primary: "#C3CCD5",
        secondary: "#F8998C",
        accent: "#037971",
        error: "#ED254E",
        success: "#4C1E4F",
        warning: "#F9DC5C",
      },
    },
    options: {
      customProperties: true,
    },
  },
});
